<!-- The CookieBanner component, displayed to alert about the usage of cookies. -->
<!-- @author Yasien Kashef -->
<!-- @version 1.3.0 -->
<template>
  <div v-if="!acceptedCookies" class="cookie-banner">
    <p>
      Wir verwenden Cookies um sicherzustellen, dass Sie die grundlegenden Funktionen der Website
      nutzen können und sind daher immer aktiviert.
      Diese Cookies sind essentiell und helfen uns bei Sicherheitsproblemen und Ihrem Authentifizierungsprozess
      und bei der Einhaltung von gesetzlichen Vorschriften.
      Durch das Klicken auf die Schaltfläche "Verstanden" stimmen Sie der Verwendung dieser Cookies zu.
    </p>
    <button @click="accept()">VERSTANDEN</button>
  </div>
</template>

<script>
/**
 * CookieBanner component, displayed to alert about the usage of cookies.
 * @author Yasien Kashef
 * @version 2.0.0
 */
import Vue from "vue";

export default {
  name: "CookieBanner",
  computed: {
    acceptedCookies() {
      let acceptedCookies = Vue.$cookies.get("acceptedCookies");
      console.log(acceptedCookies)
      return acceptedCookies === "true";
    }
  },
  methods: {
    accept() {
      this.$cookies.set("acceptedCookies", true);
      this.forceCompute('acceptedCookies');
    }
  }
};
</script>

<!-- Notice "scoped" attribute to limit CSS to this component only -->
<!-- @version 1.3.0 -->
<style lang="scss" scoped>
$mobileScreen: 1000px;

.cookie-banner {
  position: fixed;
  bottom: 0;
  z-index: 100;
  width: 100%;
  padding: 0 10% 20px;
  background-color: rgba(248, 248, 248, 1);
  font-size: 1rem;

  button {
    position: absolute;
    bottom: 0;
    right: 0;
    color: white;
    background-color: rgba(206, 106, 108, 1);
    font-family: "Brandon Grotesque";
    margin: 10px 20px;
    padding: 10px;
    cursor: pointer;
    border: none;
    outline: none;
    transition: all 0.25s linear;

    &:hover {
      background-color: rgba(134, 134, 134, 1);
    }
  }

  /*@media screen and (max-width: $mobileScreen) and (orientation: landscape) {
    position: fixed;
    z-index: 99;
    flex-flow: column;
    width: 20%;
    height: 25%;
  }*/
}
</style>
