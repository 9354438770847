/**
 * The main.js is the starting point of the Virtual-Showroom Website.
 * It imports the needed Framework Vue.js, the main Vue-Application App, router.js and store.js.
 * Finally it creates the Vue Application, renders and mounts the whole application to the index.
 * @author Yasien Kashef
 * @version 2.0.0
 * @requires Vue : to use the Vue.js-Framework.
 * @requires App.vue : to access the App.vue, the main module of the ControlCenter.
 * @requires router.js : to use Router of Vue.js
 */
import Vue from 'vue'
import App from './App.vue'
import router from './router/router'
import VueCookies from 'vue-cookies'
import '@/assets/fonts/fonts.scss';      // Imports the CI fonts to be used.

Vue.use(VueCookies)
Vue.config.productionTip = false  // Prevents the production tip on Vue startup in the browser developer console.

/**
 * Global Functions
 */
Vue.mixin({
	methods: {
	  fontResizer(text) {
		const baseFontSize = 6; // rem
		let deviation = (text.length)/28;
		const resizedFontSize = baseFontSize - deviation;
		return resizedFontSize+'rem'
	  },
	  forceCompute(computedName, forceUpdate /* default: true */) {
		if (this._computedWatchers[computedName]) {
			this._computedWatchers[computedName].run();
			if (forceUpdate || typeof forceUpdate == 'undefined') this.$forceUpdate()
		}
	  }
	}
})

/**
 * The main Vue Application. Gets mounted in the <div id="app"> in index.html located under "../public"
 * @requires store.js : to link to store.js, as Vuex.
 * @requires router.js : to link to router.js, as Vue Router.
 * @requires App.vue : to render the main app.
 */
new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
