<!-- The LoginForm component, used in the Login view. -->
<!-- @author Yasien Kashef -->
<!-- @version 1.2.3 -->
<template>
  <div class="login-form">
    <span class="welcome">WILLKOMMEN IM</span>
    <!-- Logo -->
    <img class="logo" src="@/assets/login_view/logo.svg" alt="Estella Showroom Logo">
    <span class="request">BITTE MELDEN SIE SICH AN:</span>
    <!-- Username input -->
    <div class="input-row">
      <label for="username-input">BENUTZER:</label>
      <input type="text" id="username-input" name="username-input" required @keyup.enter="signIn()" />
    </div>
    <!-- Password input -->
    <div class="input-row">
      <label class="password-input" for="password-input">PASSWORT:</label>
      <input type="password" id="password-input" name="password-input" required autocomplete="current-password" @keyup.enter="signIn()" />
      <img src="@/assets/login_view/eye_icon.png" alt="Eye Icon" @click="showPassword()">
    </div>
    <!-- Sign-In button -->
    <button @click="signIn()">ABSCHICKEN</button>
  </div>
</template>

<script>
/**
 * LoginForm component used in the Login view.
 * It handles the authentication logic.
 * @author Yasien Kashef
 * @version 2.0.0
 */

export default {
  name: "LoginForm",
  methods: {
    showPassword() {
      let passwordInput = document.getElementById("password-input");
      passwordInput.type = passwordInput.type === "password" ? "text" : "password";
    },
    signIn() {
      // Credentials
      const usernameAuth = "HW21";
      const passwordAuth = "Estella2021";
      // Get username
      let username = document.getElementById("username-input").value;
      // Get password
      let password = document.getElementById("password-input").value;
      if(username === usernameAuth && password === passwordAuth) {
        console.log("User is authenticated");
        this.$cookies.set("isAuthenticated", true);
        this.$router.push("Home");
      }
      else {
        alert("Das von dir eingegebene Benutzername/Passwort ist falsch.");
      }
    }
  }
};
</script>

<!-- Notice "scoped" attribute to limit CSS to this component only -->
<!-- @version 2.0.0 -->
<style lang="scss" scoped>
.login-form {
  display: flex;
  flex-flow: column;
  align-items: center;
  width: 600px;
  padding: 100px 100px 70px;
  background-color: rgba(255, 255, 255, 1);
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2), 0 5px 20px rgba(0, 0, 0, 0.2);
  letter-spacing: 3px;
  position: relative;
  top: 15%;
  margin: 0 auto;

  .welcome {
    margin-bottom: 10px;
    font-size: 1.4rem;
    font-weight: 500;
  }

  .logo {
    width: 70%;
  }

  .request {
    margin: 40px 0;
  }

  .input-row {
    width: 100%;
    margin-bottom: 5px;

    label {
      padding-right: 5px;

      &.password-input {
        padding-right: 3px;
      }
    }

    input {
      width: 70%;
      height: 35px;
      padding: 5px 10px 0;
      border: 1px solid rgba(135, 135, 135, 1);
      outline: 1px rgba(135, 135, 135, 1);
      font-family: "Brandon Grotesque";
      font-size: 1.4rem;
    }

    img {
      position: absolute;
      width: 35px;
      margin-left: 5px;
      cursor: pointer;

      &:hover {
        opacity: 0.5;
      }
    }
  }

  button {
    width: 70%;
    height: 35px;
    margin: 5px 1% 0;
    align-self:flex-end;
    border: none;
    outline: none;
    background-color: rgba(135, 135, 135, 1);
    box-shadow: 0 1px 50px rgba(0, 0, 0, 0.1), 0 1px 50px rgba(0, 0, 0, 0.1);
    font-family: "Brandon Grotesque";
    font-size: 1rem;
    color: white;
    letter-spacing: 3px;
    cursor: pointer;
    transition: all 0.5s linear;

    &:hover {
      background-color: white;
      color: rgba(135, 135, 135, 1);
    }
  }

  /* Portrait Phones and Tablets */
  @media (max-width: 768px) and (orientation: portrait) {
    width: 100%;
    padding: 10% 10% 5%;

    .welcome {
      font-size: 5vw;
    }

    .request {
      margin: 10% 0 5%;
      font-size: 4vw;
    }

    .input-row {
      display: flex;
      flex-flow: column;
      align-items: center;

      input {
        width: 100%;
      }

      img {
        position: absolute;
        bottom: 22%;
        right: 5px;
        width: 30px;
        align-self: flex-end;
      }
    }

    button {
      margin: 5% 0;
      width: 100%;
    }
  }

  /* Landscape Phones */
  @media (hover: none) and (max-width: 900px) and (orientation: landscape) {
    width: 50%;
    padding: 1% 5% 0%;
    position: relative;
    top: 5%;
    left: 35%;
    margin: 0;

    .welcome {
      font-size: 2vw;
    }

    .logo {
      width: 50%;
    }

    .request {
      margin: 2.5% 0;
      font-size: 1.5vw;
    }

    .input-row {
      display: flex;
      flex-flow: column;
      align-items: center;

      input {
        width: 100%;
      }

      img {
        position: absolute;
        bottom: 23%;
        right: 5px;
        width: 30px;
        align-self: flex-end;
      }
    }

    button {
      margin: 5% 0;
      width: 100%;
    }
  }
}
</style>
