/**
* The router.js is responsible for the Routing Management of the Virtual-Showroom Website.
* It uses Vue Router the official router for Vue.js.
* It imports the needed Framework Vue.js, Vue Router and the Login view.
* It creates all routes to all views and does the loading and import of the respective views.
* @author Yasien Kashef
* @version 1.0.0
* @requires Vue : to use the Vue.js-Framework.
* @requires VueRouter : to use Vue Router.
* @requires Login : to use the Login.vue view as the default route.
*/
import Vue from "vue";
import VueRouter from "vue-router";
import Login from "@/views/Login.vue";
 
Vue.use(VueRouter); // to use the plugin vue-router globally
 
/**
 * The Router of the Virtual-Showroom Website, which gets included in the main vue-application.
 * The paths and routings are virtual, they dont correspond exactly to the views and files.
 * Just the component imports needs to point to the correct path of the view.
 * @since 1.0.0
 * @requires Login.vue : to use the Login.vue view, as the default view.
 */
const router = new VueRouter({
  routes: [
    {
      path: "/",
      redirect: "/Home"
    },
    {
      path: "/Login",
      name: "Login",
      component: Login
    },
    {
      path: "/LegalPolicies",
      name: "LegalPolicies",
      props: true,
      component: () =>
        import(/* webpackChunkName: "LegalPolicies" */ "@/views/LegalPolicies.vue")
    },
    {
      path: "/Home",
      name: "Home",
      component: () =>
        import(/* webpackChunkName: "Home" */ "@/views/Home.vue"),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/Room",
      name: "Room",
      component: () =>
        import(/* webpackChunkName: "Room" */ "@/views/Room.vue"),
      meta: {
        requiresAuth: true
      }
    }
  ]
});

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    let isAuthenticated = Vue.$cookies.get("isAuthenticated");
    isAuthenticated = (isAuthenticated === "true");
    console.log("User authenticated: "+isAuthenticated);

    if (!isAuthenticated) {
      next("/Login")
    } else {
      next();
    }
  } else {
    next();
  }
})
 
export default router;