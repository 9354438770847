<!-- The Login view of the Virtual-Showroom Website, default Route. -->
<!-- @author Yasien Kashef -->
<!-- @version 1.2.0 -->
<template>
  <div class="login">
    <div class="overlay"></div>
    <LoginForm />
    <BottomBar />
  </div>
</template>

<script>
/**
 * Login view of the Virtual-Showroom Website,
 * It contains the LoginForm and the BottomBar.
 * @author Yasien Kashef
 * @version 1.0.0
 */
import LoginForm from "@/components/LoginForm.vue"
import BottomBar from "@/components/BottomBar.vue"


export default {
  name: "Login",
  components: {
    LoginForm,
    BottomBar
  },
  created() {
    this.$cookies.remove("isAuthenticated");
  }
};
</script>

<!-- Notice "scoped" attribute to limit CSS to this component only -->
<!-- @version 2.0.0 -->
<style lang="scss" scoped>
$mobileScreen: 1000px;

.login {
  width: 100%;
  height: 100vh;
  background: url("../assets/login_view/background.jpg") no-repeat center;
  background-size: cover;

  .overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 0;
    background-color: rgba(255, 255, 255, 0.4);
  }
}
</style>
