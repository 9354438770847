<!-- The Main-<template> of the Virtual-Showroom Website, containing the router-view. -->
<!-- @author Yasien Kashef -->
<!-- @version 2.0.0 -->
<template>
  <div id="app">
    <!-- The current Router-View, default is Login.vue. We need the key to fire the mounting cycle with query changes. -->
    <router-view :key="$route.fullPath"/>
    <CookieBanner />
  </div>
</template>

<script>
/**
 * App.vue, the main application component.
 * @author Yasien Kashef
 * @version 2.0.0
 */
import CookieBanner from "@/components/CookieBanner.vue"

export default {
  components: {
    CookieBanner
  }
};
</script>

<!-- @version 2.0.0 -->
<style lang="scss">
body {
  margin: 0;
  width: 100vw;
  height: 100vh;
  background-color: white;
  color: rgba(134, 134, 134, 1);
  overflow-x: hidden;
  font-family: "Brandon Grotesque", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  image-rendering: -webkit-optimize-contrast;
} 

* {
  box-sizing: border-box;
}

#app {
  width: 100%;
}
</style>
